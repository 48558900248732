import { User, UserRole } from '../models/user';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InitializeAction, LoginAction, LogoutAction, RegisterAction } from '../redux/actions/authAction';
import axios from 'src/utils/axios';
import { verify, JWT_SECRET } from 'src/utils/jwt';
import type { AppThunk } from '../redux/store';
import { useSelector } from '../redux/store';

interface UserState {
    loading: boolean;
    idSelected: number;
    page: number;
    limit: number;
    total: number;
    tabSelected: number | 'all'
}

export type PageUserAction = {
    type: 'SET_PAGE_USER';
    payload: {
        page: number;
    };
};

export type LimitUserAction = {
    type: 'SET_LIMIT_USER';
    payload: {
        limit: number;
    };
};

export type TotalUserAction = {
    type: 'SET_TOTAL_USER';
    payload: {
        total: number;
    };
};

export type LoadingAction = {
    type: 'LOADING_USER';
    payload: {
        loading: boolean;
    };
};

export const initialState: UserState = {
    loading: false,
    idSelected: 0,
    page: 0,
    limit: 10,
    total: 0,
    tabSelected: 'all'
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        SET_PAGE_USER: (state: UserState, action: PageUserAction): UserState => {
            const { page } = action.payload;
            return {
                ...state,
                page
            };
        },
        SET_LIMIT_USER: (state: UserState, action: LimitUserAction): UserState => {
            const { limit } = action.payload;
            return {
                ...state,
                limit
            };
        },
        SET_TOTAL_EVENT: (state: UserState, action: TotalUserAction): UserState => {
            const { total } = action.payload;
            return {
                ...state,
                total
            };
        },
        LOADING_USER: (state: UserState, action: LoadingAction): UserState => {
            const { loading } = action.payload;
            return {
                ...state,
                loading
            };
        },
    }
});

export const reducer = slice.reducer;

export const getListCategories = async (): Promise<any> => {
    let output = null

    try {
        const response = await axios.get('/api/ticket/category');
        output = response.data.payload;

    } catch (err) {
        console.error('listCategoriesException', err);
    }


    return output;
}

export const getListUsers = async (page: number, limit: number): Promise<any> => {
    let output = null
    try {
        const response = await axios.get(`/api/user/list?page=${page}&size=${limit}`);
        output = response.data.payload;
    } catch (err) {
        console.error('listTicketsException', err);
    }

    return output;
}

export const createUser = async (data: any): Promise<any> => {
    let output = null
    try {
        const response = await axios.post('/api/profile/new', {
            "email": data.email,
            "password": data.password,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "profileCode": data.profileCode,
            "phone": data.phone,
            "rut": data.rut,
            "realAddress": data.realAddress,
        });

        output = response;
    } catch (err) {
        console.error('createuserException', err);
        output = err.data
    }

    return output;
}

export const updateUser = async (data: any): Promise<any> => {
    let output = null
    try {
        const response = await axios.post('/api/profile/update', {
            "uuid": data.id,
            "email": data.email,
            "password": data.password,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "profileCode": data.profileCode,
            "phone": data.phone,
            "rut": data.rut,
            "realAddress": data.realAddress,
        });

        output = response;
    } catch (err) {
        console.error('createuserException', err);
    }

    return output;
}

export const deleteUser = async (id: string): Promise<any> => {
    let output = null
    try {
        const response = await axios.post(`/api/profile/delete/${id}`);

        output = response;
    } catch (err) {
        console.error('deleteUserException', err);
        output = err.data
    }

    return output;
}

export const suspendUser = async (id: string): Promise<any> => {
    let output = null
    try {
        const response = await axios.post(`/api/profile/disable/${id}`);

        output = response;
    } catch (err) {
        console.error('suspendUserException', err);
        output = err.data
    }

    return output;
}


export const setLimit =
    (limit: number): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(slice.actions.SET_LIMIT_USER({
                limit: limit
            }))
        };

export const setPage =
    (page: number): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(slice.actions.SET_PAGE_USER({
                page: page
            }))
        };

export const setLoading =
    (flag: boolean): AppThunk =>
        async (dispatch): Promise<void> => {
            dispatch(slice.actions.LOADING_USER({
                loading: flag
            }))
        };

export default slice;